import React, { useEffect } from "react"
import { graphql } from "gatsby"

import { Container, SEO, Comments } from "../components"
import {
  FormatDate,
  NonStretchedImage,
  Quote,
  Social,
  TagsList,
} from "../components/shared"

export default function CreatePagesTemplate({ pageContext, data }) {
  const { type } = pageContext
  const { markdownRemark: post } = data
  const commentBox = React.createRef()
  useEffect(() => {
    const commentScript = document.createElement("script")
    const theme =
      typeof window !== "undefined" && localStorage.getItem("dark") === "true"
        ? "github-dark"
        : "github-light"
    commentScript.async = true
    commentScript.src = "https://utteranc.es/client.js"
    commentScript.setAttribute(
      "repo",
      "KrishnaPrasanthVemuganti/comments.krishnaprasanth.com"
    )
    commentScript.setAttribute("issue-term", "pathname")
    commentScript.setAttribute("id", "utterances")
    commentScript.setAttribute("theme", theme)
    commentScript.setAttribute("crossorigin", "anonymous")
    if (commentBox && commentBox.current) {
      commentBox.current.appendChild(commentScript)
    } else {
      console.log(`Error adding utterances comments on: ${commentBox}`)
    }
  }, []) // eslint-disable-line

  return (
    <Container title={post.frontmatter.title}>
      <SEO postPath={post.frontmatter.path} postNode={post} postSEO />
      <article className="blogPost">
        {post.frontmatter.featuredImage && (
          <div className="blogImg">
            <NonStretchedImage
              fluid={post.frontmatter.featuredImage.childImageSharp.fluid}
            />
          </div>
        )}
        <h1>{post.frontmatter.title}</h1>
        <div className="row">
          <div className="column sixty leftNoChange blogDateTime">
            {FormatDate(post.frontmatter.date)} · {post.fields.readingTime.text}
          </div>
          <div className="column forty rightNoChange">
            <Social
              share={post.frontmatter.path}
              title={post.frontmatter.title}
            />
          </div>
        </div>

        {type === "post" && (
          <TagsList tags={post.frontmatter.tags} type="tags" />
        )}
        <div
          className="blogHTML"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        {post.frontmatter.quote && post.frontmatter.cite && (
          <Quote quote={post.frontmatter.quote} cite={post.frontmatter.cite} />
        )}
      </article>
      <Comments commentBox={commentBox} />
    </Container>
  )
}

export const pageQuery = graphql`
  query PagesNPostsByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      excerpt
      tableOfContents(pathToSlugField: "frontmatter.path")
      frontmatter {
        title
        date
        path
        tags
        description
        keywords
        quote
        cite
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 950, quality: 100) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
            fixed(width: 500, quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
